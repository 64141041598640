/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { dehydrate } from '@tanstack/react-query';
import Router from 'next/router';
import type { PageContextType } from '../../next-types';
import { landingPagesToRedirect } from '../../constants/landingPagesToRedirect';
import { LandingPageComp } from '@/features/landingPage/pages/LandingPage';
import {
  prefetchLandingPageData,
  getLandingPageDataFromCache,
} from '@/features/landingPage/api/getLandingPageData';
import {
  prefetchContentStackLandingPageData,
  getContentStackLandingPageDataFromCache,
} from '@/features/landingPage/api/getContentStackLandingPageData';
import {
  isLandingPageHandledByContentStack,
  isTemporaryCSLandingPage,
} from '@/features/landingPage';
import { prefetchPreFooterData } from '@/features/prefooter/api/getPreFooterData';
import { configureSharedPageData } from '@/utils/configureSharedPageData.server';
import { getPathnameFromAsPath } from '@/utils/urlHelpers';
import { getHeaderDataFromCache } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { getKiboDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { getFeatureFlag, getGlobalConfigFromCache } from '@/features/globalConfig';

export const config = {
  unstable_JsPreload: false,
};

function LPPage() {
  return <LandingPageComp />;
}

LPPage.getInitialProps = async (ctx: PageContextType) => {
  const { query, asPath, res } = ctx;

  if (landingPagesToRedirect.includes(query.pageName)) {
    const redirectPath =
      query.pageName === 'duralast-parts' ? '/duralast' : asPath.replace('lp/', '');
    __IS_SERVER__
      ? res?.writeHead(301, { Location: redirectPath }).end()
      : Router.replace(redirectPath);
    return {};
  }

  if (__IS_SERVER__) {
    const { axios, queryClient, initialAppState } = await configureSharedPageData(ctx);
    const globalConfig = getGlobalConfigFromCache(queryClient);
    const headerData = getHeaderDataFromCache(queryClient);
    const catalogVehicleId = getPreferredVehicle(headerData)?.catalogVehicleId ?? '0';
    const contentStackLandingPageEnabled = isLandingPageHandledByContentStack(
      getKiboDecisionFlag(queryClient, 'cmsLandingPagePhaseOneEnabled'),
      initialAppState.locale,
      query.pageName
    );

    const prefetchFooterCall = contentStackLandingPageEnabled
      ? null
      : prefetchPreFooterData(
          queryClient,
          {
            locale: initialAppState.locale,
            seourl: getPathnameFromAsPath(asPath).replace(/(\/bn\/|\/b\/).*/, ''),
            vehicleId: catalogVehicleId,
            isCMSEnabled: false,
            gcpUrlEnabled: getFeatureFlag(globalConfig, 'SEO_GCP_URL_ENABLED') === 'true',
          },
          axios
        );
    const prefetchLandingPageCall = contentStackLandingPageEnabled
      ? prefetchContentStackLandingPageData(queryClient, ctx, headerData?.segments ?? [])
      : prefetchLandingPageData(queryClient, query.pageName, axios);
    await Promise.all([prefetchFooterCall, prefetchLandingPageCall]);

    const landingPageData = contentStackLandingPageEnabled
      ? getContentStackLandingPageDataFromCache(
          queryClient,
          query.pageName,
          headerData?.segments ?? []
        )
      : getLandingPageDataFromCache(queryClient, query.pageName);

    const pageNotFound = Boolean(
      contentStackLandingPageEnabled
        ? !landingPageData
        : landingPageData?.['@error'] === 'FileNotFound'
    );

    if (
      landingPageData === undefined &&
      isTemporaryCSLandingPage(contentStackLandingPageEnabled, query.pageName)
    ) {
      // landingPageData === undefined means the temporary landing page is not currently published
      res?.writeHead(302, { Location: '/' }).end();
    } else if (pageNotFound) {
      res?.writeHead(302, { Location: '/NotFound' }).end();
    }

    return {
      dehydratedState: dehydrate(queryClient),
      initialAppState,
    };
  }

  return {};
};

export default LPPage;
